#modal-window {
  .modal-body, .modal-body p, h1, h2, h3, h4, h5, h6, li {
    color: $color-base;
  }

  button.btn {
    @include butt();
    &.btn-secondary {
      background-color: $color-body-grey;
      &:hover {
        background-color: $color-accent;
      }
    }

  }
}
