.flex-it {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -moz-box-wrap: nowrap;
  -webkit-box-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  -ms-flexbox-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap
}

.flex-row {
  -moz-box-direction: row;
  -webkit-box-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flexbox-direction: row;
  -ms-flex-direction: row;
  flex-direction: row
}

.flex-col {
  -moz-box-direction: column;
  -webkit-box-direction: column;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flexbox-direction: column;
  -ms-flex-direction: column;
  flex-direction: column
}

.flex-align-between {
  -webkit-box-align-content: space-between;
  -webkit-align-content: space-between;
  -ms-flex-align-content: space-between;
  align-content: space-between
}

.flex-align-center {
  -webkit-box-align-content: center;
  -webkit-align-content: center;
  -ms-flex-align-content: center;
  align-content: center
}

.flex-align-start {
  -webkit-box-align-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-align-content: flex-start;
  align-content: flex-start
}

.flex-align-item-start {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -moz-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start
}

.flex-align-item-end {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -moz-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end
}

.flex-align-item-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.flex-start-all {
  -webkit-box-pack: justify;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: justify;
  -moz-box-pack: justify;
  justify-content: flex-start;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -moz-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-align-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-align-content: flex-start;
  align-content: flex-start
}

.flex-align-item-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

.flex-justify-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  -moz-box-pack: justify;
  justify-content: space-between
}

.flex-justify-center {
  -webkit-box-pack: justify;
  -webkit-justify-content: center;
  -ms-flex-pack: justify;
  -moz-box-pack: justify;
  justify-content: center
}

.flex-justify-start {
  -webkit-box-pack: justify;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: justify;
  -moz-box-pack: justify;
  justify-content: flex-start
}

.flex-justify-end {
  -webkit-box-pack: justify;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: justify;
  -moz-box-pack: justify;
  justify-content: flex-end
}

.flex-wrap {
  -moz-box-wrap: wrap;
  -webkit-box-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flexbox-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.flex-item-auto {
  -webkit-box-basis: auto;
  -webkit-flex-basis: auto;
  -ms-flex-basis: auto;
  flex-basis: auto;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1
}

.mgtop {
  margin-top: 50px;
}

.mgbot {
  margin-bottom: 50px;
}

.mgtop-big {
  margin-top: 100px;
}

.mgbot-big {
  margin-bottom: 100px;
}


.loader {
  background: rgba(230, 230, 230, 0.6) url("../../assets/images/loading.gif") center center no-repeat;
  background-size: 50px 50px;
  position: fixed;
  height: 100%;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  -webkit-transition: all .1s ease-out;
  -moz-transition: all .1s ease-out;
  -o-transition: all .1s ease-out;
  transition: all .1s ease-out;
  display: none;
  z-index: 9999
}

.loader.inplace {
  display: block
}

form.clwt-form {
  text-align: left
}

form.clwt-form .form-element {
  padding: 15px 0;
  clear: both
}

form.clwt-form .form-element label {
  display: block;
  float: left;
  width: 20%;
  text-align: right;
  margin-right: 15px;
  padding-top: 10px
}

form.clwt-form .form-element input[type=text] {
  width: 75%;
  float: right
}

form.clwt-form .form-element.submit {
  margin-top: 20px;
  text-align: right
}

.form input {
  margin: 0;
  box-sizing: border-box;
  padding: 10px 15px;
  font-size: 16px;
  color: #062a3d;
  line-height: 130%;
  -webkit-box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
  box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 0;
  background-color: transparent;
  border: 1px solid #d7ddd9;
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
  font-weight: 300
}

.form input.submit {
  padding: 13px
}

#map, .acf-map {
  width: 100%;
  height: 500px;
  margin: 0;
  border: none;
}

.acf-map img {
  max-width: inherit !important
}

#acf-map {
  height: 500px;
  @media handheld, only screen and (min-height:850px) {
    height: 600px
  }
  @media handheld, only screen and (min-height:950px) {
    height: 700px
  }
  @media handheld, only screen and (min-height:1050px) {
    height: 800px
  }

  .marker {
    display: none;
  }
  h4 {
    font-size: 18px;
  }
  h1, h2, h3, h4, h5, &, p {
    color: $color-text !important;
  }

  img.stop-logo {
    float: right;
    max-width: 100px;
    max-height: 80px;
    display: block;
    margin: 0 0 20px 20px;
  }
}

span.remove {
  display: none;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 10px
}

.selected span.remove {
  display: inline-block
}

.sortable {
  margin: 20px 0 20px -6px;
  padding: 0
}

.sortable li {
  cursor: move;
  padding: 3px 6px;
  margin: 0;
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
  border: 1px solid transparent
}

.sortable li span {
  float: right;
  display: none;
  cursor: pointer
}

.sortable li:hover span {
  display: block
}

.sortable li:hover {
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.1)
}

.expander .the-details {
  display: none
}

h3.edit-name {
  cursor: pointer
}

h3.edit-name:hover {
  background: url("../../assets/images/icon-edit.png") right center no-repeat;
  background-size: 13px auto
}

.itinerary-list-container {
  position: absolute;
  top: 110px;
  left: 0;
  z-index: 500;
  width: 250px;
  max-height: 75vh;
  overflow: scroll;

  h3 {
    font-size: 17px;
  }

  .itinerary-list {
    background: #fff;
    background: rgba(252, 252, 252, 0.97);
    padding: 20px;
    font-size: 15px;

    .itinerary-ordered-list {
      li {
        font-family: $font-headline;
        font-size: 14px;
        margin-left: 25px;
        line-height: 110%;
      }
    }
  }

}



@media handheld, only screen and (max-width: 768px) {
  .itinerary-list-container {
    position: static;
    width: auto;
    max-height: auto;
    overflow: scroll
  }
  .itinerary-list-container .itinerary-list {
    background: transparent;
    padding: 10px;
    font-size: 15px;
  }
}

.itinerary .logo {
  float: right;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 83px;
  height: 83px;
  padding: 4px;
  margin: 0 0 20px 20px
}

.itinerary .logo img {
  width: 75px;
  height: 75px
}

.itinerary ul.list, .itinerary ul.list li {
  margin: 0;
  padding: 0
}

.itinerary ul.list li {
  margin: 0;
  list-style: none;
  border-bottom: 1px solid #d7ddd9;
  padding: 15px 0
}

.itinerary ul.list li a.butt {
  padding: 4px 7px
}

.itinerary ul.list li p {
  margin: 0
}

.itinerary ul.list li p.addr {
  margin-top: 15px
}

.itinerary ul.list li:last-child {
  border: 0
}

.lab-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  background: rgba(255, 255, 255, 0.96);
  z-index: 500;
  text-align: center;
  padding: 50px;
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
  display: block;
  overflow: scroll;
  display: none;
}

@media handheld, only screen and (max-width: 768px) {
  .lab-modal {
    padding: 20px;
  }
}

.lab-modal .close {
  display: inline-block;
  padding: 10px 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.15em;
  line-height: 100%
}

.lab-modal.create-new {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -moz-box-wrap: nowrap;
  -webkit-box-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  -ms-flexbox-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-align-content: center;
  -webkit-align-content: center;
  -ms-flex-align-content: center;
  align-content: center;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=100)";
  filter: "alpha(opacity=100)"
}

.lab-modal.display {
  display: block
}

.lab-modal.inplace {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=100)";
  filter: "alpha(opacity=100)"
}

.lab-modal div.inner {
  width: 100%
}

form.enter-address input {
  width: 79%
}

@media handheld, only screen and (max-width: 768px) {
  form.enter-address input {
    width: 100%;
  }
}

form.enter-address input.butt {
  width: 20%
}

@media handheld, only screen and (max-width: 768px) {
  form.enter-address input.butt {
    width: auto;
  }
}

.outer-points input {
  width: 100%;
  margin-bottom: 10px
}

ul.errors {
  text-align: left;
  max-width: 400px;
  margin: 30px auto
}

.toggle-all {
  float: right
}

.summary {
  float: right
}

.directions h3 {
  margin-bottom: 2px;
  display: inline-block
}

.directions .distance {
  float: right;
  font-weight: 500;
  font-size: 20px
}

.directions .details, .directions .distance {
  margin-top: 40px
}

@media handheld, only screen and (max-width:768px) {
  .directions .details {
    margin-top: 0
  }
}

.directions .address, .directions .notes {
  margin-bottom: 5px
}

.directions .trigger {
  color: #346094;
  cursor: pointer
}

@media print {
  .directions .trigger {
    display: none
  }
}

.directions ol {
  clear: both;
  margin-top: 10px
}

.directions ol, .directions ol li {
  margin: 0;
  padding: 0
}

.directions ol li {
  border-bottom: 1px solid #d7ddd9;
  padding: 2px 0;
  margin-left: 15px
}

.directions ol li span {
  float: right
}

.directions ol li:last-child {
  border: 0
}



// Facet styles
.facetwp-checkbox {
  text-align: left;
}

section.trail-filters {
  margin: 0;
  padding: 30px 0 0 0;
  .map-filter {
    text-align: center;
    margin: 0;
    padding: 0;
    .facetwp-facet {
      margin: 0;
      padding: 0;
    }
  }
}
.map-filters {
  position: fixed;
  width: 300px;
  height: 100vh;
  padding: 80px 20px 20px 20px;
  top: 100px;
  left: 0;
  left: -305px;
  text-align: left;
  z-index: 600;
  background: #fff;
  background: rgba(255, 255, 255, .95);
  @include transition(.4s);

  .wrapper.map-filters-open & {
    left: 0;
  }
}

.map-filters-icon {
    cursor: pointer;
    position: fixed;
    z-index: 9999;
    top: 100px;
    left: 10px;
    @include transition(.4s);

    .wrapper.map-filters-open & {
      left: 240px;
    }

    .tcon {
      background: transparent !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none !important;
      cursor: pointer;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      height: 50px;
      transition: 0.3s;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 30px;
      background: transparent;
      outline: none;
      -webkit-tap-highlight-color: transparent;
      -webkit-tap-highlight-color: transparent;
    }

    .tcon > * {
      display: block;
    }

    .tcon:focus,
    .tcon:hover {
      outline: none;
      background: transparent;
    }

    .tcon::-moz-focus-inner {
      border: 0;
    }

    .tcon-menu__lines {
      display: inline-block;
      height: 2px;
      width: 30px;
      //border-radius: 2.85714px;
      transition: 0.3s;
      background: $color-hamburger;
      position: relative;
    }

    .tcon-menu__lines::after,
    .tcon-menu__lines::before {
      display: inline-block;
      height: 2px;
      width: 30px;
      //border-radius: 2.85714px;
      transition: 0.3s;
      background: $color-hamburger;
      content: '';
      position: absolute;
      left: 0;
      -webkit-transform-origin: 2.85714px center;
      transform-origin: 2.85714px center;
      width: 100%;
    }

    .tcon-menu__lines::before {
      top: 10px;
    }

    .tcon-menu__lines::after {
      top: -10px;
    }

    .tcon-transform .tcon-menu__lines {
      -webkit-transform: scale3d(0.8, 0.8, 0.8);
      transform: scale3d(0.8, 0.8, 0.8);
    }

    .tcon-menu--xcross {
      width: auto;
    }

    .tcon-menu--xcross.tcon-transform .tcon-menu__lines {
      background: transparent;
    }

    .tcon-menu--xcross.tcon-transform .tcon-menu__lines::after,
    .tcon-menu--xcross.tcon-transform .tcon-menu__lines::before {
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      top: 0;
      width: 40px;
    }

    .tcon-menu--xcross.tcon-transform .tcon-menu__lines::before {
      -webkit-transform: rotate3d(0, 0, 1, 45deg);
      transform: rotate3d(0, 0, 1, 45deg);
      //background: $color-hamburger-menu-open;
    }

    .tcon-menu--xcross.tcon-transform .tcon-menu__lines::after {
      -webkit-transform: rotate3d(0, 0, 1, -45deg);
      transform: rotate3d(0, 0, 1, -45deg);
      //background: $color-hamburger-menu-open;
    }

    .tcon-visuallyhidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    .tcon-visuallyhidden:active,
    .tcon-visuallyhidden:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
}







section.trail-stops-grid {
  .trail-stop {
    margin-top: 30px;
    margin-bottom: 30px;
    //text-align: center;

    img {
      margin-bottom: 20px;
    }
  }
}
