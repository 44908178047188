

//
// Custom
//
div.flexslider {
  margin-bottom: -10px;
  display: block;

  .flex-control-paging li a {
    background: #fff;
    @include opacity(.3);
  }

  .flex-control-paging li a:hover {
    @include opacity(1);
    background: $color-accent-secondary;
    border-color: $color-accent-secondary;
  }

  .flex-control-paging li a.flex-active {
    @include opacity(1);
    background: $color-accent-secondary;
    border-color: $color-accent-secondary;
  }

  .flex-direction-nav a:before {
    background: url("#{$images}/arrow-left.png") top center no-repeat;
    background-size: 35px 68px;
    @include opacity(.3);
    @include transition();

    @media handheld, only screen and (max-width: 768px) {
      display: none;
    }
  }

  .flex-direction-nav a:hover:before {
    @include opacity(1);
  }

  .flex-direction-nav a.flex-next:before {
    background: url("#{$images}/arrow-left.png") top center no-repeat;
    background-size: 35px 68px;
    @include rotation(180deg);
  }

  ul.slides {
    li {
      &, p, h1, h2, h3, h4, h5, h6 {
        //color: #fff;
      }

      //@include opacity(.4);
      background-size: cover;
      background-position: center center;
      padding: 50px 0;
      .content {
        width: 65%;
        margin: 0 auto;
        @include media-breakpoint-down(sm) {
          width: 100%;
          margin: 0 25px;
        }
      }
      &.loaded {
        .overlay {
          //background: transparent;
        }
      }
    }
  }
}

section.quotes.fcslider {
  .flexslider {
    p {
      font-family: $font-headline;
      font-size: 24px;
      line-height: 145%;
    }
  }
}

.fcslider {
  div.flexslider {
    background: rgba(0, 0, 0, .02);
    margin-bottom: -10px;
    display: block;
    &,
    ul.slides,
    ul.slides li {

        height: 550px;
        @media handheld, only screen and (max-width: 991px) {
          height: 450px;
        }
        @media handheld, only screen and (max-width: 768px) {
          height: 380px;
        }
      }

    ul.slides {
      li {
        background-size: cover;
        background-position: center center;
        padding: 0;
        .overlay {
          background: rgba(0,0,0,.3);
          height: 100%;
          padding: 0 80px;
          @include media-breakpoint-down(sm) {
            padding: 0;
          }
        }

        &.bg-top {
          background-position: center top;
        }
        &.bg-bottom {
          background-position: top bottom;
        }

        &.slide-center {
          .overlay {
            @include flex-it;
            @include flex-align-item-center;
          }
        }
        &.slide-bottom {
          .overlay {
            @include flex-it;
            @include flex-align-item-end;
            padding-bottom: 50px;
          }
        }
        &.slide-top {
          .overlay {
            @include flex-it;
            @include flex-align-item-start;
            padding-bottom: 50px;
          }
        }

        .content {
          margin: 0 auto;
          width: 100%;
          max-width: 800px;
          &, p, h1, h2, h3, h4 {
            color: #fff;
          }
          h1,
          p {
            text-shadow: 0 0 7px rgba(0, 0, 0, .7);
          }
          a.butt {
            text-shadow: none;
          }

          p {
            @include media-breakpoint-down(sm) {
              margin-left: auto;
              margin-right: auto;
            }
          }

          h1 {
            @include media-breakpoint-down(sm) {
              font-size: 35px;
            }
          }

          @include media-breakpoint-down(sm) {
            width: auto;
            margin: 0 25px;
          }
        }
      }
    }
  }
}
