$color-base: #2E2F39;
$color-text: lighten(#2E2F39, 15%);
$color-subtle: rgba(0, 0, 0, .4);
$color-accent: #CE9800;
$color-admin-accent: #CE9800;
$color-headings: $color-base;
$color-accent-secondary: #CE9800;
$color-accent-third: darken($color-accent, 3%);
$color-overlay-rgba: rgba(0, 0, 0, .35);
$color-neutral: #A0A0A0;
$color-body-grey: #B5B5B5;
$color-light-text: #B5B5B5;
$color-light-text-forms: rgba(0, 0, 0, .55);
$color-light: #EFEFEF;
$color-grey: #E5E5E5;
$color-subtle-background: #F7F5F2;
$color-subtle-background-secondary: #EBF3F7;
$color-on-base: #889998;
$color-grey-on-base: #637575;
$color-light-box: #F7F7F7;
$color-hr: #D7DDD9;
$color-hamburger: $color-base;
$color-hamburger-menu-open: #fff;

$font-sans:	"futura-pt", sans-serif;
$font-serif: "futura-pt", sans-serif;
$font-body:	"futura-pt", sans-serif;
$font-headline: "clarendon-urw", sans-serif;
$font-menu:	"futura-pt", sans-serif;
$images: "../../assets/images";
