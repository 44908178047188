.footer.footer {
  background: darken($color-base, 5%);
  margin: 100px 0 0 0;
  padding: 30px;
  text-align: left;

  .footer-inner {
    padding-top: 60px;
    padding-bottom: 0px;
  }
  &, p, ul li {
    font-size: 14px;
  }

  h3 {
    font-size: 15px;
    letter-spacing: .1em;
    text-transform: uppercase;
    margin-bottom: 9px;
    font-family: $font-body;
    font-weight: 500;
  }

  p {
    margin: 0;
  }
  a.butt {
    padding: 12px;
  }
  a.butt {
    background: $color-accent-third;
  }
  a.butt:hover {
    background: $color-accent-secondary;
  }

  .left {
    text-align: left;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
    a.butt {
      background: $color-accent-third;
    }
    a.butt:hover {
      background: $color-accent-secondary;
    }
  }
  .right {
    text-align: right;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }


  .legal {
    margin-top: 30px;
    //text-transform: uppercase;
    //letter-spacing: .09em;
    font-size: 14px;
    line-height: 135%;
    text-align: center;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
    p {
      margin: 0;
    }
    p, &, a {
      color: rgba(255,255,255, .3);
    }
    a:hover {
      color: $color-accent-third;
    }
  }


  .sn {
    //text-align: left;
    margin-top: 25px;
      text-align: center;
      @include flex-justify-center;
    .svg & {
      @include flex-it;
      @include flex-align-item-center;
      text-align: center;
      @include media-breakpoint-down(sm) {
        text-align: center;
        @include flex-align-item-center;
      }
    }
    a {
      .svg & {
        background-color: $color-accent-secondary;
        width: 40px;
        height: 40px;
        @include rounded(25px);
        @include flex-it;
        @include flex-align-item-center;
        svg {
          margin: 0 auto;
          .st0 {
            fill: #fff;
          }
        }
        &:hover {
          background-color: $color-accent-third;
          svg {
            .st0 {
              fill: #fff;
            }
          }
        }
      }
    }
  }
}
