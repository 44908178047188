@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}


.page-title {
  background: linear-gradient(-45deg, $color-base, lighten($color-base, 30%), $color-base, lighten($color-base, 40%));
	background-size: 200% 200%;
	-webkit-animation: Gradient 5s ease infinite;
	-moz-animation: Gradient 5s ease infinite;
	animation: Gradient 5s ease infinite;
  clear: both;



  .overlay {
    height: 350px;
    box-sizing: border-box;
    padding: 30px 30px;
    text-align: center;
    background: $color-overlay-rgba;
    @include media-breakpoint-down(sm) {
      height: 200px;
    }
    &.no-content {
      background: transparent;
    }
    @include flex-it;
    @include flex-align-item-center;

    .flex-container {
      margin: 0 auto;
      flex-grow: 1;
    }

    h1,
    h2,
    h3,
    h4,
    p {
      color: #fff;
    }

    h1 {
      margin: 0 auto;
      padding: 0;
      //font-weight: 200;
      //text-transform: none;
      //letter-spacing: 0;
      //font-size: 35px;
      //font-family: $font-sans;
      //line-height: 130%;
      max-width: 70%;
      @include media-breakpoint-down(sm) {
        //font-size: 29px;
      }
      @include media-breakpoint-down(xs) {
        max-width: 100%;
      }
      html.wf-active & {
        //font-size: 19px;
        //line-height: 100%;
      }
    }

    p {
      margin: 15px 25px 0;
    }
  }

  &.content-bottom .overlay {
    @include flex-align-item-end;
  }
  &.content-top .overlay {
    @include flex-align-item-start;
  }
}

html {
  .page-title {
      &.inplace {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-animation: none;
      	-moz-animation: none;
      	animation: none;

        &.img-bottom {
          background-position: center bottom;
        }
        &.img-top {
          background-position: center top;
        }
      }
  }
}
