
section {
  //border: 1px solid $color-hr;
}

html body main.main {
  padding: 0;
}



.container-fluid.mgtop {
  margin-top: 40px;
}




/* =================================================
     Regular tag styling
   ============================================== */
body {
  background: #fff;
}

body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
}

body,
input,
li,
td,
textarea {
  font-family: $font-serif;
  font-size: 18px;
  color: $color-text;
  font-weight: 400;
  html.wf-active & {
    //font-size: 19px;
    //line-height: 100%;
  }
}
@media handheld, only screen and (max-width: 767px) {
  body,
  input,
  li,
  td,
  textarea {
    font-size: 16px;
  }
}

.wrapper {
  width: 100%;
  background: $color-base;
  background: url("#{$images}/wave-pattern.png") center center repeat;
  background-size: 120px auto;

  h1, h2, h3, h4, h5, h6, p {
    color: #fff;
  }
  &, p, li {
    color: #C3CCD1;
  }
}

p {
  margin: 0 0 1.3em;
  line-height: 180%;
}

b {
  font-weight: normal;
}

::selection {
  background: $color-accent;
  /* Safari */
  color: #fff;
}

::-moz-selection {
  background: $color-accent;
  /* Firefox */
  color: #fff;
}

ol li,
ul li {
  margin-bottom: 8px;
}

ul li h4 {
  display: inline-block;
}

th {
  font-family: $font-sans, sans-serif;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px;
  line-height: 130%;
  color: $color-headings;
  font-family: $font-headline;
  font-weight: 300;
}

h1 {
  font-size: 58px;
  margin: 0 0 25px;
  line-height: 120%;
  font-style: normal;
  @include media-breakpoint-down(sm) {
    font-size: 50px;
  }

  html.wf-active & {
    font-size: 55px;
    @include media-breakpoint-down(sm) {
      font-size: 42px;
    }
  }
  span {
    display: block;
    color: $color-base;
  }
}
h2 {
  font-size: 47px;
  line-height: 100%;
  @include media-breakpoint-down(sm) {
    font-size: 34px;
  }
  html.wf-active & {
    font-size: 43px;
    line-height: 120%;
    @include media-breakpoint-down(sm) {
      font-size: 30px;
    }
  }

  @media handheld, only screen and (max-width: 768px) {
    font-size: 31px;
  }
}

h3 {
  margin-bottom: 29px;
  line-height: 100%;
  font-size: 28px;
  @include media-breakpoint-down(sm) {
    font-size: 23px;
  }
  html.wf-active & {
    font-size: 25px;
    line-height: 120%;
    @include media-breakpoint-down(sm) {
      font-size: 20px;
    }
  }
}

h4 {
  font-size: 23px;
  line-height: 100%;
  margin: 0 0 14px;
  html.wf-active & {
    font-size: 21px;
    line-height: 120%;
  }
}

h5 {
  font-size: 15px;
  font-family: $font-body;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .08em;
  margin: 0 0 14px;
  html.wf-active & {
    //font-size: 22px;
    //line-height: 110%;
  }
}

h6 {
  font-size: 15px;
  font-family: $font-body;
  text-transform: uppercase;
  letter-spacing: .08em;
  margin: 0 0 14px;
  html.wf-active & {
    //font-size: 22px;
    //line-height: 110%;
  }
}

blockquote {
  border-left: 6px solid $color-accent;
  padding-left: 30px;
}

.hl {
  font-size: 20px;
  color: rgba(0, 0, 0, .45);
}

.hl-alt {
  font-size: 18px;
}
// Breakpoint #3
@media handheld, only screen and (max-width: 768px) {
  .hl {
    font-size: 18px;
  }
}

hr {
  height: 1px;
  border: none;
  border-bottom: 1px solid $color-hr;
  background: transparent;
  margin: 40px 0;
  clear: both;
}

hr.sm-pd {
  margin: 20px 0;
}
// Color bar divider.

hr.mini {
  width: 30px;
  margin: 20px 0;
  display: block;
  border-bottom: 2px solid $color-accent-third;
  text-align: left;

  &.c2 {
    border-color: $color-accent-secondary;
  }

  &.mgtop {
    margin-top: 50px;
  }

  &.mgbot {
    margin-bottom: 50px;
  }
}

hr.ctr {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

b,
strong {
  font-weight: 600;
}

.sub {
  font-size: 13px;
  letter-spacing: 0.1em;
  line-height: 100%;
  margin: 0 0 30px;
  display: block;
  color: $color-accent-secondary;
}
// Mobile
@media handheld, only screen and (max-width: 767px) {
  .sub {
    font-size: 12px;
    line-height: 100%;
    margin: 0 0 8px;
  }
}

i {
  font-style: italic;
}

a:active,
a:hover,
a:link,
a:visited {
  color: $color-accent;
  text-decoration: none;
  @include transition(.2s);
  padding-bottom: 0;
  border-bottom: none;
}

a:hover {
  color: #000;
}

fieldset {
  border: 1px solid $color-hr;
  padding: 15px 0 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  margin: 0;

  .submit-container {
    background: $color-grey;
    padding: 15px;
    text-align: center;
  }
}

fieldset legend {
  padding: 10px;
  font-size: 18px;
  color: $color-accent;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
}

picture {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

  img {
    width: 100%;
    display: block;
  }
}

.ctr {
  text-align: center;
}

/* =================================================
  Standard mobile styles
============================================== */
// Breakpoint #1
@media handheld, only screen and (max-width: 1150px) {}
// Breakppint #2
@media handheld, only screen and (max-width: 1023px) {}
// Breakpoint #3
@media handheld, only screen and (max-width: 768px) {}
/* =================================================
     Page layout - widths, etc.
   ============================================== */
.container-fluid {
  max-width: 1600px;
  padding: 0;
}

.row {
  margin-left: auto;
  margin-right: auto;
  padding: 0 50px;
  @media handheld, only screen and (max-width: 1000px) {
    padding: 0 20px;
  }
  @media handheld, only screen and (max-width: 768px) {
    padding: 0;
  }
}











/*

This generates classes like this:
mt-3-md-up (Adds margin top to md cols of size 3)

The number is the size of the padding or margin 1 through 5.
*/

$class-prefix-list: mt mb; //mr and ml can be added if needed
$breakpoint-direction-list: up down;
$breakpoint-list: xs sm md lg;

@mixin spacer-dynamic($breakpoint, $direction, $size, $breakpoint-direction) {
@if($breakpoint-direction == 'up') {
    @include media-breakpoint-up($breakpoint) {
        @if $direction == 'mb' {
            margin-bottom: ($spacer/2) * ($size/2) !important;
        }

        @if $direction == 'mt' {
            margin-top: ($spacer/2) * ($size/2) !important;
        }

        @if $direction == 'mr' {
            margin-right: ($spacer/2) * ($size/2) !important;
        }

        @if $direction == 'ml' {
            margin-left: ($spacer/2) * ($size/2) !important;
        }
    }
}

@else if($breakpoint-direction == 'down') {
    @include media-breakpoint-down($breakpoint) {
        @if $direction == 'mb' {
            margin-bottom: ($spacer/2) * ($size/2) !important;
        }

        @if $direction == 'mt' {
            margin-top: ($spacer/2) * ($size/2) !important;
        }

        @if $direction == 'mr' {
            margin-right: ($spacer/2) * ($size/2) !important;
        }

        @if $direction == 'ml' {
            margin-left: ($spacer/2) * ($size/2) !important;
        }
    }
  }
}

@each $breakpoint in $breakpoint-list {
@each $breakpoint-direction in $breakpoint-direction-list {
    @each $direction in $class-prefix-list {
        @for $i from 1 through 6 {
            .#{$direction}-#{$i}-#{$breakpoint}-#{$breakpoint-direction} {
                @include spacer-dynamic($breakpoint, $direction, $i, $breakpoint-direction);
            }
        }
    }
}
}
